import '../../styles/App.css'

import React, { useEffect, useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import useInterval from 'react-useinterval'
import { tryRunWithLock } from 'localstorage-lock'
import { jwtDecode } from 'jwt-decode'
import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'
import { useIdleTimer } from "react-idle-timer"
import styled from 'styled-components';

import { LoginPage } from '../../components/LoginPage'
import { DirectLogin } from '../../components/LoginPage/DirectLogin'
import { useStore, getAuthToken, getRefreshToken } from '../../state/store'
import { LoggedOutPage } from '../../components/LoggedOutPage'
import { Header } from '../Header'
import { ProfilePage } from '../../components/ProfilePage'
import { TransactionsPage } from '../../components/TransactionsPage'
import { ConsentsPage } from '../../components/ConsentsPage'
import { DataProcessingPage } from '../../components/DataProcessingPage'
import { Footer } from '../Footer'
import { Sitemap } from '../../components/Sitemap'
import { NotFoundPage } from '../../components/NotFoundPage'
import { BypassBlock } from '../../components/common/BypassBlock'
import { ProtectedRoute } from './ProtectedRoute'
import { CallbackPage } from '../../components/LoginPage/Callback'
import { ContactPage } from '../../components/ContactPage'
import { ContactLogList } from '../../components/ContactPage/ContactLogList'
import { ContactLog } from '../../components/ContactPage/ContactLogList/ContactLog'

import { backend } from '../../api/backend'
import {
	LOGIN_HOME_PATH,
	PROFILE_PATH,
	TRANSACTIONS_PATH,
	CONSENTS_PATH,
	DATA_PROCESSING_PATH,
	SITEMAP_PATH,
	HOME_PATH,
	CALLBACK_PATH,
	CONTACT_PATH,
	CONTACT_PATH_LOG_LIST,
	CONTACT_PATH_LOG,
	LOGGED_OUT_PATH,
	DIRECT_LOGIN_PATH
} from '../Header/Menu/routes'
import { removePersonalData, setAuthenticated, setAuthToken, setRefreshToken, toggleMenu } from '../../state/actions'

import messages from '../../translations/messages'

const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
`;

const MainContent = styled.main`
    flex: 1; 
`;

export const App = () => {
	const { dispatch, state } = useStore()
	const intl = useIntl()
	const navigate = useNavigate()
	const [isIdle, setIsIdle] = useState(false)

	const handleOnIdle = () => {
		if (state.isAuthenticated) {
			setIsIdle(true)
		}
	}

	const handleOnActive = () => {
		if (state.isAuthenticated) {
			setIsIdle(false)
		}
	}

	useEffect(() => {
		if (isIdle) {
			logOutUserIfIdle()
		}
	}, [isIdle])

	useIdleTimer({
		timeout: 900000, // 15 minutes
		crossTab: true,
		onIdle: handleOnIdle,
		onActive: handleOnActive,
		events: ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchstart']
	});

	const logOutUserIfIdle = () => {
		if (isIdle) {
			backend
				.get("/oidc/logout")
				.then(response => {
					dispatch(setAuthenticated(false))
					dispatch(setAuthToken(null))
					dispatch(setRefreshToken(null))
					dispatch(removePersonalData())
					if (state.menuIsOpen) {
						dispatch(toggleMenu())
					}
					navigate(LOGGED_OUT_PATH)
				})
				.catch(error => {
					dispatch(setAuthenticated(false))
					dispatch(setAuthToken(null))
					dispatch(setRefreshToken(null))
					dispatch(removePersonalData())
					if (state.menuIsOpen) {
						dispatch(toggleMenu())
					}
					navigate(LOGGED_OUT_PATH)
				})
		}
	}

	const getNewTokensFromRefreshToken = refreshToken => {
		backend
			.post('/oauth2/token', { refreshToken: refreshToken, grantType: 'refresh_token' })
			.then(function (response) {
				if (response.status === 201) {
					dispatch(setAuthenticated(true))
					dispatch(setAuthToken(response.data.accessToken))
					dispatch(setRefreshToken(response.data.refreshToken))
				} else if (response.status === 401) {
					logOutUserIfIdle()
				}
			})
			.catch(error => {
				console.warn('Error receiving token from refresh token. User must re-authenticate to see new info:', error)
				logOutUserIfIdle()
			})
	}

	// return the number of seconds since 1.1.1970
	const epoch = () => {
		let millis = Date.now()
		return Math.floor(millis / 1000)
	}

	const isTokenValid = token => {
		const TOKEN_GRACE_PERIOD = 30 //seconds
		let decoded

		if (token === null || token === undefined) {
			return false
		}

		try {
			decoded = jwtDecode(token)
		} catch (err) {
			console.warn('Error decoding token')
			return false
		}

		let valid = parseInt(decoded.exp) - TOKEN_GRACE_PERIOD > parseInt(epoch())
		return valid
	}

	// Update tokens
	useInterval(
		() => {
			tryRunWithLock(
				'consumer.lock.updatetokens',
				() => {
					let authToken = getAuthToken()
					let refreshToken = getRefreshToken()
					if (!isTokenValid(authToken) && isTokenValid(refreshToken)) {
						getNewTokensFromRefreshToken(refreshToken)
					} else if (!isTokenValid(authToken) && !isTokenValid(refreshToken)) {
						console.warn('Tokens expired, logging out user if idle')
						logOutUserIfIdle()
					}
				},
				{
					timeout: 5000, // Time release the lock if function fails or takes too long
					lockWriteTime: 2000, // Expected time to write to localstorage (unlikely to change)
					retry: false // Retry getting the lock, if not acquired
				}
			)
		},
		state.isAuthenticated ? 3000 : null // run every 3 seconds if authenticated
	)

	return (
		<AppWrapper>
			<MainContent>
				<BypassBlock />
				<Helmet
					htmlAttributes={{ lang: state.locale }}
					titleTemplate={`%s | ${intl.formatMessage(messages.DefaultHelmet)}`}
					defaultTitle={intl.formatMessage(messages.DefaultHelmet)}
				>
					<meta name="description" content={intl.formatMessage(messages.DefaultHelmet)} />
				</Helmet>
				<Header />

				<Routes>
					<Route path={HOME_PATH} exact element={state.isAuthenticated ? <ProfilePage /> : <LoginPage />} />
					<Route path={DIRECT_LOGIN_PATH} element={<DirectLogin />} />
					<Route path="*" element={<NotFoundPage />} />
					<Route path={CALLBACK_PATH} element={<CallbackPage />} />
					<Route path={LOGIN_HOME_PATH} element={<LoginPage />} />
					<Route path={LOGGED_OUT_PATH} element={<LoggedOutPage />} />
					<Route
						path={PROFILE_PATH}
						element={
							<ProtectedRoute>
								<ProfilePage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={TRANSACTIONS_PATH}
						element={
							<ProtectedRoute>
								<TransactionsPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={CONSENTS_PATH}
						element={
							<ProtectedRoute>
								<ConsentsPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={DATA_PROCESSING_PATH}
						element={
							<ProtectedRoute>
								<DataProcessingPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path={CONTACT_PATH}
						element={
							<ProtectedRoute>
								<ContactPage />
							</ProtectedRoute>
						}
					/>
					<Route
						exact={true}
						path={CONTACT_PATH_LOG + '/:logId'}
						element={
							<ProtectedRoute>
								<ContactLog />
							</ProtectedRoute>
						}
					/>
					<Route
						exact={true}
						path={CONTACT_PATH_LOG_LIST}
						element={
							<ProtectedRoute>
								<ContactLogList />
							</ProtectedRoute>
						}
					/>
					<Route
						path={SITEMAP_PATH}
						element={
							<ProtectedRoute>
								<Sitemap />
							</ProtectedRoute>
						}
					/>
				</Routes>
			</MainContent>
			<Footer />
		</AppWrapper>
	)
}
