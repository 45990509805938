import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'

import styled from 'styled-components'
import { SROnlyParagraph, StyledContainer } from '../../styles/styledComponents'
import { getPath, useStore } from '../../state/store'

import { backend } from '../../api/backend'

import {
	removePersonalData,
	setActiveLink,
	setAuthenticated,
	setAuthToken,
	setFetchedLoginFromTimeoutUrl,
	setRefreshToken,
	toggleMenu
} from '../../state/actions'

import { STORAGE_CODE_VERIFIER } from '../../utils/storageUtil'
import { AeraSpinner } from '../common/AeraSpinner'
import { menuItemsIndex } from '../../utils/menuUtil'
import { LOGIN_HOME_PATH } from '../../containers/Header/Menu/routes'

const StyledWrapper = styled.div`
	height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding-top: 60px;
	padding-bottom: 120px;
`

const StyledHeaderWrapper = styled.div`
	margin-bottom: -40px;
	height: 40vw;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

/*
 * Component used as callback in oidc flow:
 * Rendered after user has authenticated via Aera IDP
 * Receives and sets access- and refresh token for API access
 * */
export const CallbackPage = () => {
	let [searchParams] = useSearchParams()

	const { state, dispatch } = useStore()
	const navigate = useNavigate()

	const _codeVerifier = JSON.parse(localStorage.getItem(STORAGE_CODE_VERIFIER))

	const ERROR_TYPES = {
		NO_CUSTOMER_ERROR: 'NO_CUSTOMER_ERROR',
		NETWORK_ERROR: 'NETWORK_ERROR'
	}

	const goToFrontPage = errorType => {
		dispatch(setAuthenticated(false))
		dispatch(setAuthToken(null))
		dispatch(setRefreshToken(null))
		dispatch(removePersonalData())
		if (state.menuIsOpen) {
			dispatch(toggleMenu())
		}
		dispatch(setActiveLink(menuItemsIndex.LOGIN_PAGE))
		if(!errorType){
			navigate(LOGIN_HOME_PATH, { state: { userCancelled: true } })
		} else if (errorType === ERROR_TYPES.NO_CUSTOMER_ERROR) {
			navigate(LOGIN_HOME_PATH, { state: { showNotCustomerFeedback: true } })
		} else {
			navigate(LOGIN_HOME_PATH, { state: { showNetworkError: true } })
		}
	}

	const hasMountedRef = useRef(false)

	useEffect(() => {
		const controller = new AbortController()
		let _code = searchParams.get('code')
		let _state = searchParams.get('state')
		let _error = searchParams.get('error_description')

		if(!hasMountedRef.current){
			if (_code) {
				backend
					.get(`oidc/tokens?code=${_code}&state=${_state}&code_verifier=${_codeVerifier}`, { signal: controller.signal }) //
					.then(response => {
						dispatch(removePersonalData())
						dispatch(setAuthToken(response.data?.accessToken))
						dispatch(setRefreshToken(response.data?.refreshToken))
						dispatch(setAuthenticated(true))
						dispatch(setFetchedLoginFromTimeoutUrl(null))
						navigate(getPath())
					})
					.catch(error => {
						const { response = {} } = error
						console.warn('Error during log in, logging out user: ', error)
						if (response?.status === 404 && response?.data?.responseInfo?.responseCode === "CP-10001") {
							goToFrontPage(ERROR_TYPES.NO_CUSTOMER_ERROR)
						} else {
							goToFrontPage(ERROR_TYPES.NETWORK_ERROR)
						}
					})
				hasMountedRef.current = true
			} else {
				if(_error && !_error.includes("User cancelled")){
					goToFrontPage(ERROR_TYPES.NETWORK_ERROR)
				} else {
					goToFrontPage()
				}
				return () => controller.abort()
			}
		}
	}, [])

	return (
		<StyledContainer>
			<StyledWrapper>
				<StyledHeaderWrapper>
					<div aria-hidden="true">
						<AeraSpinner />
					</div>
					<SROnlyParagraph aria-live="polite">Loading...</SROnlyParagraph>
				</StyledHeaderWrapper>
			</StyledWrapper>
		</StyledContainer>
	)
}
