import React, { useState } from "react"
import { useIntl } from "react-intl"

import { removePersonalData, setAuthenticated, setAuthToken, setRefreshToken, toggleMenu } from "../../../state/actions"
import { LOGIN_HOME_PATH } from "../../../containers/Header/Menu/routes"
import { useStore } from "../../../state/store"

import messages from "../../../translations/messages"
import { StyledSecondSection } from "../../../styles/styledComponents"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Colors } from "../../../styles/Colors"
import { Button } from "../../common/Button"
import { Modal } from "../../common/Feedback/Modal"
import { backend } from "../../../api/backend"
import { AeraAlert } from "../../common/Feedback/AeraAlert"
import { renderContactSupportLink } from "../../../utils/feedbackUtil"
import { H2, P } from "../../../styles/Typography"

const StyledDeleteButton = styled(Button)`
    margin-left: 20px;
`

const StyledButton = styled(Button)`
    margin-top: 26px;
`

export const DeleteProfileSection = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { dispatch, state } = useStore()

    const [showDeleteMeModal, setShowDeleteMeModal] = useState(false)
    const [deleteUserError, setDeleteUserError] = useState(false)

    const logOutUser = () => {
        backend
            .get("/oidc/logout")
            .then(response => {
                dispatch(setAuthenticated(false))
                dispatch(setAuthToken(null))
                dispatch(setRefreshToken(null))
                dispatch(removePersonalData())
                if (state.menuIsOpen) {
                    dispatch(toggleMenu())
                }
                navigate(LOGIN_HOME_PATH, { state: { showUserDeletionStarted: true } })
            })
            .catch(error => {
                console.warn('Error during log out: ', error)
                dispatch(setAuthenticated(false))
                dispatch(setAuthToken(null))
                dispatch(setRefreshToken(null))
                dispatch(removePersonalData())
                if (state.menuIsOpen) {
                    dispatch(toggleMenu())
                }
                navigate(LOGIN_HOME_PATH, { state: { showUserDeletionStarted: true } })
            })

    }

    const handleDeleteUser = () => {
        backend
            .delete("/consumer")
            .then(() => {
                logOutUser()
            })
            .catch(() => {
                setDeleteUserError(true)
            })
    }

    const renderDeleteButtons = () => {
        return (
            <>
                <Button width="50%" cancel medium onClick={() => setShowDeleteMeModal(false)}>
                    {intl.formatMessage(messages.NoCancelLabel)}
                </Button>
                <StyledDeleteButton width="50%" danger medium onClick={() => handleDeleteUser()}>
                    {intl.formatMessage(messages.DeleteMeModalAcceptLabel)}
                </StyledDeleteButton>
            </>
        )
    }

    return (
        <StyledSecondSection backgroundColor={Colors.aeraWhite}>
            <Modal
                id="delete-modal"
                open={showDeleteMeModal}
                handleClose={() => setShowDeleteMeModal(false)}
                title={intl.formatMessage(messages.DeleteMeModalTitle)}
                subTitle={intl.formatMessage(messages.DeleteMeModalSubTitle)}
                headerColor={Colors.aeraRed}
                renderAdditionalInfo={() => renderDeleteButtons()}
            />
            {deleteUserError && (
                <AeraAlert severity="error" handleClose={() => setDeleteUserError(false)}>
                    {intl.formatMessage(messages.FeedbackErrorDuringUserDeletion, { callToAction: renderContactSupportLink(intl) })}
                </AeraAlert>
            )}
            <H2>{intl.formatMessage(messages.DeleteMeTitle)}</H2>
            <P>{intl.formatMessage(messages.DeleteMeDescription)}</P>
            <StyledButton medium danger outlined onClick={() => setShowDeleteMeModal(true)}>
                {intl.formatMessage(messages.DeleteMeButtonLabel)}
            </StyledButton>
        </StyledSecondSection>
    )
}
